.holder {
    display: flex;
    justify-content: center;
}

.text {
    --red: rgb(244, 77, 77);
    --green: rgb(50, 255, 128);
    --blue: rgb(51, 51, 240);
    opacity: 0;
    text-align: center;
    /* font-size: 64px; */
    font-weight: bold;
    animation-name: party-effect;
    animation-duration: 700ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    padding: 0;
    margin: 0;
}

@keyframes party-effect {
    0% {
        transform: translate(0);
        color: var(--green);
        opacity: 0;
    }
    20% {
        transform: translate(-2px, 2px);
        color: var(--green);
    }
    40% {
        transform: translate(2px, -2px);
        color: var(--blue);
    }
    60% {
        transform: translate(-2px, 2px);
        color: var(--red);
    }
    80% {
        transform: translate(2px, -2px);
        color: var(--blue);
    }
    90% {
        transform: translate(2px, 2px);
        color: var(--green);
        opacity: 1;
    }
    100% {
        transform: translate(0);
        color: white;
        opacity: 1;
    }
}
