.body {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    width: 100%;
}

.page {
    opacity: 0;
    animation-duration: 0.75s;
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    align-items: center;
    justify-content: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 100%;
    }
}
