.page1 {
	height: 800px;
}

.page3 {
	width: 100%;
	height: 1000px;
	margin-top: 80px;
}

.subtitle {
	margin: 0 48px !important;
}

.aboutText {
	color: white;
	font-size: 1.2em;
	z-index: 0;
	margin: 0 48px;
}

.mainTitle {
	font-family: 'Work Sans', sans-serif !important;
	margin-bottom: 48px;
}

.title {
	color: white;
	font-size: 15vw;
	text-align: center;
	opacity: 0;
	animation-duration: 0.75s;
	animation-name: fadeIn;
	animation-delay: 0.25s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
}

@keyframes fadeIn {
	from {
			opacity: 0;
	}
	to {
			opacity: 100%;
	}
}

.signinButton {
	z-index: 5;
	margin-top: 48px;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 16px;
	backdrop-filter: blur(4px);
}

.qrcode {
	width: 200px;
	height: 200px;
	border-radius: 5px;
}