.text {
    color: white;
    text-align: center;
    font-size: 1.5em;
    margin: 0em 10em 0em 10em;
}

.fixedText {
    color: white;
    font-size: 1.5em;
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}

.title {
    color: white;
    font-size: 5em;
    text-align: center;
    opacity: 0;
    animation-duration: 0.75s;
    animation-name: fadeIn;
    animation-delay: 0.25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    margin-top: 30vh;
    font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
}

.mainTitle {
    font-family: 'Work Sans', sans-serif !important;
    margin-bottom: 48px;
}

.fixedTitle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    color: white;
    font-size: 4em;
    text-align: center;
    opacity: 0;
    animation-duration: 0.75s;
    animation-name: fadeIn;
    animation-delay: 0.25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
}

.overlapLayer {
    position: relative;
}


.topLayer {
    position: relative;
    z-index: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 100%;
    }
}

.sponsorSpots {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.sponsorHolder {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    width: fit-content
}

.sponsorSpot {
    width: fit-content;
    margin: 0 50px;
}

.sponsorImage {
    height: 100px;
}

.signinButton {
    position: fixed;
    bottom: -100px;
    left: 50%;
    transform: translate(-50%, -30%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 16px;
    backdrop-filter: blur(8px);
}

.qrtext {
    color: white;
    font-size: 4rem;
    font-weight: 600;
    text-decoration: none;
    transition: color 300ms ease-in-out;
    margin: 32px;
}

.qrtext:hover {
    color: #afc3ff;
    transition: color 300ms ease-in-out;
}

.qrcode {
    width: 400px;
    height: 400px;
    border-radius: 10px;
}